import Vue from 'vue';
import ImageCarousel from '@/components/ImageCarousel.vue';

Vue.config.productionTip = false;

function accordionStatus() {
  const accordion = getCookie('accordion');
  if (window.location.hash === '#referenzen' && accordion === 'active') {
    const panel = document.querySelector('div.panel') as HTMLElement;
    if (panel) {
      panel.style.transition = 'max-height 0s ease-in-out';
      panel.style.maxHeight = (panel.style.maxHeight ? null : panel.scrollHeight + 'px');
      setTimeout(() => { panel.style.transition = 'max-height 1s ease-in-out'; }, 500);
    }
  } else {
    setCookie('accordion', 'inactive', 0);
  }
}

accordionStatus();

new Vue({
  render: (h) => h(ImageCarousel),
}).$mount('image-carousel');

// Aktiven Menüeintrag hervorheben

const links = document.querySelectorAll('nav ul li');
const articles = document.querySelectorAll('article');

function scrollAction() {
  const a: HTMLElement | null = document.querySelector('header');
  const b: HTMLElement | null  = document.querySelector('main');
  if (document.body.offsetWidth < 600) {
    if (b !== null) {
      b.style.cssText = 'margin-top: 50px;';
    }
  } else {
    const sticky = 370;
    if (a !== null && b !== null) {
      if (window.pageYOffset >= sticky) {
        a.style.cssText = 'position: fixed; top: -370px; width: 100%; z-index: 2;';
        b.style.cssText = 'margin-top: 538px;';
      } else {
        a.style.cssText = '';
        b.style.cssText = 'margin-top: 50px;';
      }
    }
  }
  // Linkstatus anpassen
  const length = articles.length;
  // gesucht höchster index j mit window.scrollY>articles[j].offsetTop
  let max: number = length - 1;
  let j: number = 0;
  let test: boolean = true;
  const diff = (document.body.offsetWidth < 600 ? 0 : 410);

  articles.forEach ((article) => {
    if (test && window.pageYOffset - diff <= article.offsetTop) {
      max = j - 1;
      if (max < 0) { max = 0; }
      test = false;
    }
    j++;
  });
  links.forEach((link) => link.classList.remove('active'));
  links[max].classList.add('active');
}

scrollAction();
window.addEventListener('scroll', scrollAction);

// Accordion

const accList: NodeList = document.querySelectorAll('section.accordion');

accList.forEach ((node) => {
  if (!node) { return; }
  const handle: HTMLElement = node as HTMLElement;
  if (!handle) { return; }
  const a = handle.querySelector('div.button');
  if (!a) { return; }
  a.addEventListener('click', (event: Event) => {
    a.classList.toggle('active');
    if (a.classList.contains('active')) {
      setCookie('accordion', 'active', 1);
    }
    window.location.hash = 'referenzen';
    const panel = document.querySelector('div.panel') as HTMLElement;
    if (panel) {
      panel.style.maxHeight = (panel.style.maxHeight ? null : panel.scrollHeight + 'px');
    }
  });
});

// Hamburger-Menü

const menuIcon: HTMLElement | null = document.getElementById('menu_icon');
const menu: HTMLElement | null = document.getElementById('menu');

if (menuIcon && menu) {
  menuIcon.addEventListener('click', (event: Event) => {
    const status: boolean = menuIcon.classList.contains('active');
    menu.style.opacity = (status ? '0' : '1');
    menu.style.maxWidth = (status ? '0px' : '200px');
    menuIcon.classList.toggle('active');
  });
}

function setCookie(cname: string, cvalue: string, exdays: number) {
  const d = new Date();
  d.setTime(d.getTime() + (exdays * 24 * 60 * 60 * 1000));
  const expires: string = 'expires=' + d.toUTCString();
  document.cookie = cname + '=' + cvalue + ';' + expires + ';SameSite=Strict;path=/';
}

function getCookie(cname: string) {
  const name = cname + '=';
  const decodedCookie = decodeURIComponent(document.cookie);
  const ca = decodedCookie.split(';');
  for (let c of ca) {
    while (c.charAt(0) === ' ') {
      c = c.substring(1);
    }
    if (c.indexOf(name) === 0) {
      return c.substring(name.length, c.length);
    }
  }
  return '';
}
