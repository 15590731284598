
















































import Vue from 'vue';
import { animatedScroll } from '@/helpers/animated-scroll';
import { Slide } from '@/types';

export default Vue.extend({

  data() {
    return {
      slides: [
        {
          text: ['Veränderungen', 'begünstigen nur den,', 'der darauf vorbereitet ist.'],
          author: 'Louis Pasteur',
        },
        {
          text: ['Wenn der Wind', 'der Veränderung weht,', 'bauen die einen Mauern', 'und die anderen Windmühlen.'],
          author: 'Chinesisches Sprichwort',
        },
        {
          text: ['Jede grosse Reise beginnt', 'mit einem kleinen Schritt.'],
          author: 'Chinesisches Sprichwort',
        },
      ] as Slide[],
      currentSlideIndex: 3 as number,
      classNames: [['visible', 'hidden', 'hidden'], ['hidden', 'visible', 'hidden'], ['hidden', 'hidden', 'visible'], ['start', 'hidden', 'hidden']],
    };
  },

  created() {
    setTimeout(this.nextSlide, 100);
    setInterval(this.nextSlide, 7000);
  },

  methods: {
    nextSlide() {
      if (this.currentSlideIndex === 3) {
        this.currentSlideIndex = 0;
        return;
      }
      this.currentSlideIndex = (this.currentSlideIndex + 1) % this.slides.length;
    },

    scrollTo(target: string) {
      const a: HTMLElement | null = document.body;
      let position: number = 0;
      if (a !== null) {
        const advance: number = (a.offsetWidth < 600 ? 0 : 430);
        const b: HTMLElement | null = document.getElementById(target);
        if (b !== null) {
          position = b.offsetTop + advance;
        }
      }
      if (target === 'institut') {
          position = 0;
      }
      animatedScroll.scroll(position, 1000);
    },
  },
});
