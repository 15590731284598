class AnimatedScroll {
  private to: number;
  private start: number;
  private change: number;
  private duration: number;
  private startTime: number;
  private element: Element;

  constructor() {
    this.element = document.scrollingElement || document.documentElement;
    this.start = this.element.scrollTop;
    this.startTime = + new Date();
    this.to = 0;
    this.duration = 0;
    this.change = 0;
  }

  public scroll(to: number, duration: number) {
    this.to = to;
    this.start = this.element.scrollTop;
    this.duration = duration;
    this.change = this.to - this.start;
    this.startTime = + new Date();
    this.animateScroll();
  }

  private animateScroll(): void {
    const currentTime = + new Date();
    const elapsedTime = currentTime - this.startTime;
    this.element.scrollTop = this.easeInOutQuad(elapsedTime, this.start, this.change, this.duration);
    if (elapsedTime < this.duration) {
      requestAnimationFrame(this.animateScroll.bind(this));
    } else {
      this.element.scrollTop = this.to;
    }
  }

  private easeInOutQuad(t: number, b: number, c: number, d: number): number {
    t /= d / 2;
    if (t < 1) {
      return c / 2 * t * t + b;
    }
    t--;
    return -c / 2 * (t * (t - 2) - 1) + b;
  }
}

export const animatedScroll = new AnimatedScroll();
